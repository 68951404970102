import * as React from 'react';
import Box from '@mui/joy/Box';
import ListDivider from '@mui/joy/ListDivider';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import CircleIcon from '@mui/icons-material/Circle';

export type NarrativeListing = {
  id: string,
  version: string,
  live: boolean
}

type NarrativeListingViewProps = {
  listing: NarrativeListing
  selectedListing: NarrativeListing | undefined
  setSelectedListing: React.Dispatch<React.SetStateAction<NarrativeListing | undefined>>
};

export default function NarrativeListingView(props: NarrativeListingViewProps) {
  const { listing, selectedListing, setSelectedListing } = props;
  const selected = JSON.stringify(listing) === JSON.stringify(selectedListing);
  return (
    <React.Fragment>
      <ListItem>
        <ListItemButton
          selected={selected}
          color="neutral"
          sx={{ flexDirection: 'column', alignItems: 'initial', gap: 1 }}
          onClick={() => setSelectedListing(listing)}
        >
          <Stack direction="row" spacing={1.5}>
            <Box sx={{ flex: 1 }}>
              <Typography level="title-sm">{listing.id}</Typography>
              <Typography level="body-sm">{listing.version}</Typography>
            </Box>
            <Box sx={{ lineHeight: 1.5, textAlign: 'right' }}>
              {listing.live && (<>
                <CircleIcon sx={{ fontSize: 12 }} color="primary" />
                <Typography
                  level="body-xs"
                  noWrap
                  sx={{ display: { xs: 'none', md: 'block' } }}
                >
                  Live
                </Typography>
              </>)}
            </Box>
          </Stack>
        </ListItemButton>
      </ListItem>
      <ListDivider sx={{ margin: 0 }} />
    </React.Fragment>
  );
}
