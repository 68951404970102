import * as React from 'react';
import { NarrativeListing } from './NarrativeListing';
import Box from '@mui/joy/Box';
import Chip from '@mui/joy/Chip';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import AceEditor from 'react-ace';
import { NarrativeEndpoints } from './Narratives';
import PublishIcon from '@mui/icons-material/Publish';

import 'brace/mode/yaml';
import 'brace/theme/clouds_midnight';
import { ButtonGroup, Card, CircularProgress, Input, Modal } from '@mui/joy';

type NarrativeEditorProps = {
  selectedListing: NarrativeListing | undefined
  setSelectedListing: React.Dispatch<React.SetStateAction<NarrativeListing | undefined>>
  narrativeEndpoints: NarrativeEndpoints
  userJwt: string
};

export default function NarrativeEditor({selectedListing, setSelectedListing, narrativeEndpoints, userJwt}: NarrativeEditorProps) {

  const [loaded, setLoaded] = React.useState(false)
  const [body, setBody] = React.useState("")
  const [latestSaved, setLatestSaved] = React.useState("")
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [duplicateModalOpen, setDuplicateModalOpen] = React.useState(false);
  const [newID, setNewID] = React.useState("");
  const [newVersion, setNewVersion] = React.useState("");

  React.useEffect(() => {
    if (selectedListing === undefined) {
      return
    }
    setLoaded(false)
    fetch(`${narrativeEndpoints.narrativeFetchEndpoint}?id=${selectedListing.id}&version=${selectedListing.version}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${userJwt}`
      },
      mode: 'cors'
    })
    .then(res => res.json())
    .then(res => {
      setBody(res.body)
      setLatestSaved(res.body)
    })
    .then(() => setLoaded(true))
  }, [selectedListing, userJwt, narrativeEndpoints])

  function deleteNarrativeVersion() {
    fetch(narrativeEndpoints.narrativeDeleteEndpoint, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${userJwt}`
      },
      mode: 'cors',
      body: JSON.stringify({
        "id": selectedListing?.id,
        "version": selectedListing?.version
      })
    })
    .then(() => setSelectedListing(undefined))
    .then(() => setDeleteModalOpen(false))
  }

  function publish() {
    fetch(narrativeEndpoints.narrativePublishEndpoint, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${userJwt}`
      },
      mode: 'cors',
      body: JSON.stringify({
        "id": selectedListing?.id,
        "version": selectedListing?.version
      })
    })
    .then(() => setSelectedListing({...selectedListing!, live: true}))
  }

  function save() {
    fetch(narrativeEndpoints.narrativeUpdateEndpoint, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${userJwt}`
      },
      mode: 'cors',
      body: JSON.stringify({
        "id": selectedListing?.id,
        "version": selectedListing?.version,
        "live": selectedListing?.live,
        "body": body
      })
    })
    .then(() => setLatestSaved(body))
  }

  function duplicate(narrativeId: string, narrativeVersion: string) {
    fetch(narrativeEndpoints.narrativeUpdateEndpoint, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${userJwt}`
      },
      mode: 'cors',
      body: JSON.stringify({
        "id": narrativeId,
        "version": narrativeVersion,
        "body": body,
        "live": false
      })
    })
    .then(() => {
      setSelectedListing({
        "id": narrativeId,
        "version": narrativeVersion,
        "live": false
      })
      setDuplicateModalOpen(false)
    })
  }
  
  if (!selectedListing) {
    return null;
  }

  return (
    <Sheet
      variant="outlined"
      sx={{ minHeight: 500, borderRadius: 'sm', p: 2, m: 3 }}
    >
      <Modal open={duplicateModalOpen} onClose={() => setDuplicateModalOpen(false)}>
        <Box sx= {{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          p: 4}}>
          <Card variant="outlined">
            <Input placeholder="Narrative ID" value={newID} onChange={e => setNewID(e.target.value)}/>
            <Input placeholder="Version" value={newVersion} onChange={e => setNewVersion(e.target.value)}/>
            <ButtonGroup sx={{width: '100%'}} buttonFlex={1}>
              <Button onClick={() => setDuplicateModalOpen(false)}>Cancel</Button>
              <Button disabled={newID === "" || newVersion === ""} color='primary' variant='solid' onClick={() => duplicate(newID, newVersion)}>Save As</Button>
            </ButtonGroup>
          </Card>
        </Box>
      </Modal>
      <Modal open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
        <Box sx= {{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          p: 4}}>
          <Card variant="outlined">
            <Typography>Are you sure you want to delete this Narrative Version?</Typography>
            <ButtonGroup sx={{width: '100%'}} buttonFlex={1}>
              <Button onClick={() => setDeleteModalOpen(false)}>Cancel</Button>
              <Button color='primary' variant='solid' onClick={() => deleteNarrativeVersion()}>Delete</Button>
            </ButtonGroup>
          </Card>
        </Box>
      </Modal>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 2,
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ ml: 2 }}>
            <Typography
              level="title-lg"
              textColor="text.primary"
              endDecorator={ selectedListing.live && (
                <Chip component="span" size="sm" variant="outlined" color="warning">
                  Live
                </Chip>)
              }
            >
              {selectedListing.id}
            </Typography>
            <Typography level="body-sm" textColor="text.tertiary">
              {selectedListing.version}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{ display: 'flex', height: '32px', flexDirection: 'row', gap: 1.5 }}
        >
          <Button
            size="sm"
            variant="plain"
            color="neutral"
            startDecorator={<DeleteIcon />}
            onClick={() => setDeleteModalOpen(true)}
          >
            Delete
          </Button>
          <Button
            size="sm"
            variant="plain"
            color="neutral"
            startDecorator={<PublishIcon />}
            disabled={selectedListing.live || body !== latestSaved}
            onClick={() => publish()}
          >
            Publish
          </Button>
          <Button
            size="sm"
            variant="plain"
            color="neutral"
            startDecorator={<SaveAsIcon />}
            onClick={() => {
              setNewID(selectedListing!.id)
              setNewVersion(selectedListing!.version)
              setDuplicateModalOpen(true)
            }}
          >
            Save As
          </Button>
          <Button
            size="sm"
            variant="plain"
            color="primary"
            startDecorator={<SaveIcon />}
            disabled={body === latestSaved}
            onClick={() => save()}
          >
            Save
          </Button>
        </Box>
      </Box>
      <Divider sx={{ mt: 2 }} />
      {loaded ? 
        <AceEditor
          mode="yaml"
          theme="clouds_midnight"
          name="narrative-editor"
          width="100%"
          height="calc(100% - 60px)"
          value={body}
          onChange={e => setBody(e)}
          fontSize={15}
        /> : <Box sx={{width: '100%', height: '100%', alignContent: 'center', textAlign: 'center'}}><CircularProgress size="lg" /></Box>
      }
    </Sheet>
  );
}
