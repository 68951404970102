import * as React from 'react';
import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import BugReportIcon from '@mui/icons-material/BugReport';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import { Avatar, IconButton } from '@mui/joy';
import { DiagnosticPayload, RawHumanTurn } from '../controller/FablecastMessage';
import FablecastController from '../controller/FablecastController';

export interface ChatBubbleProps {
  turn: RawHumanTurn & { speakerName: string | undefined, audioStreamId: string | undefined }
  diagnostic: DiagnosticPayload | undefined
  setCurrentDiagnostic: React.Dispatch<React.SetStateAction<DiagnosticPayload | undefined>>;
}

function generateColorFromSeed(seedStr: string): string {
  const cyrb53 = (str: string, seed: number = 0) => {
      let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
      for(let i = 0, ch; i < str.length; i++) {
          ch = str.charCodeAt(i);
          h1 = Math.imul(h1 ^ ch, 2654435761);
          h2 = Math.imul(h2 ^ ch, 1597334677);
      }
      h1  = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
      h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
      h2  = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
      h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);
    
      return 4294967296 * (2097151 & h2) + (h1 >>> 0);
  };
  const hash = cyrb53(seedStr);

  // Use the hash to generate HSL values
  const hue = Math.abs(hash % 360); // 0-359
  const saturation = 70 + (hash % 30); // 70-99
  const lightness = 60 + (hash % 20); // 60-79

  // Return the color as an HSL string
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
}


export default function ChatBubble({turn, diagnostic, setCurrentDiagnostic}: ChatBubbleProps) {
  const isSent = turn.speakerId === "Player1";
  const isNPC = turn.speakerId !== "Player1" && turn.speakerId !== "storyteller";
  const [isHovered, setIsHovered] = React.useState<boolean>(false);
  const color = generateColorFromSeed(turn.speakerId);

  return (<>
    {isNPC ? (<Avatar size="sm" color="neutral" sx={{backgroundColor: generateColorFromSeed(turn.speakerId), color: "#000", fontWeight: "bold"}}>{(turn.speakerName || "?")[0]}</Avatar>) : null}
    <Box sx={{ maxWidth: '60%', minWidth: 'auto' }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{ justifyContent: 'space-between', mb: 0.25 }}
      >
        {(isNPC) ? (
          <Typography level="body-xs" sx={{color}}>
            {turn.speakerName || "Unknown"}
          </Typography>
        ) : null}

      </Stack>
        <Box
            sx={{ position: 'relative' }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <Sheet
            color={isSent ? 'primary' : 'neutral'}
            variant={isSent ? 'solid' : 'soft'}
            sx={{
                p: 1.25,
                borderRadius: 'lg',
                borderTopRightRadius: isSent ? 0 : 'lg',
                borderTopLeftRadius: isSent ? 'lg' : 0,
                backgroundColor: isSent ? 'var(--joy-palette-primary-solidBg)' : 'background.body'
            }}
            >
              { turn.message.split("\n").map((i, key) => <div key={key}>
              <Typography
                level="body-sm"
                sx={{
                color: isSent ? 'var(--joy-palette-common-white)' : 'var(--joy-palette-text-primary)'
                }}
            >{i}</Typography></div>)}

            
            </Sheet>

            {
                (isHovered && (!isSent || diagnostic !== undefined)) ? (
                  <Stack
                    direction="row"
                    justifyContent={'flex-start'}
                    spacing={0.5}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      p: 1.5,
                      right: 0,
                      transform: 'translate(100%, -50%)'
                    }}
                  >
                    {
                      (turn.audioStreamId !== undefined) ? (
                        <IconButton
                          variant={'plain'}
                          color={'neutral'}
                          size="sm"
                          onClick={() => FablecastController.playAudio(turn.audioStreamId!)}
                        >
                          <HeadphonesIcon />
                        </IconButton>
                      ) : null
                    }
                    {
                      (diagnostic !== undefined) ? (
                          <IconButton
                            variant={'plain'}
                            color={'neutral'}
                            size="sm"
                            onClick={() => setCurrentDiagnostic(diagnostic)}
                          >
                            <BugReportIcon />
                          </IconButton>
                      ) : null
                    }
                  </Stack>
                ) : null
            }
        </Box>
    </Box>
  </>);
}