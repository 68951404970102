export enum PayloadType {
    FinalizedDialogRound = 'FinalizedDialogRound',
    ProspectiveDialogRound = 'ProspectiveDialogRound',
    DialogAudioMetadata = 'DialogAudioMetadata',
    DialogAudioFrame = 'DialogAudioFrame',
    ErrorPayload = 'ErrorPayload',
    DiagnosticPayload = 'DiagnosticPayload',
    ControlPayload = 'ControlPayload'
}

export enum RoundMode {
    Finalized = 'Finalized',
    Prospective = 'Prospective'
}

export enum ControlAction {
    JoinSession = 'JoinSession',
    AnnotateGameLog = 'AnnotateGameLog',
    WriteGameLog = 'WriteGameLog'
}

export enum DialogEventType {
    Turn = 'Turn',
    RawHumanTurn = 'RawHumanTurn',
    PoiseToSpeak = 'PoiseToSpeak',
    UnpoiseToSpeak = 'UnpoiseToSpeak',
    ExpectResponse = 'ExpectResponse',
    Pause = 'Pause',
    NarrativeEventReference = 'NarrativeEventReference'
}

export class TokenCosts {
    readonly inputTokenCount: number
    readonly outputTokenCount: number
    readonly cost: number

    constructor(inputTokenCount: number, outputTokenCount: number, cost: number){
        this.inputTokenCount = inputTokenCount
        this.outputTokenCount = outputTokenCount
        this.cost = cost
    }
}

export abstract class FablecastServicePayload {
    readonly timestamp: number
    readonly versionNo: string
    readonly payloadType: PayloadType
    readonly tokenCosts?: TokenCosts

    constructor(timestamp: number, versionNo: string, payloadType: PayloadType, tokenCosts?: TokenCosts) {
        this.timestamp = timestamp
        this.versionNo = versionNo
        this.payloadType = payloadType
        this.tokenCosts = tokenCosts
    }
}

export abstract class DialogEvent {
    readonly id: string
    readonly type: DialogEventType

    constructor(id: string, type: DialogEventType) {
        this.id = id
        this.type = type
    }
}

export class ErrorPayload extends FablecastServicePayload {
    readonly message: string
    readonly errorCode: string
    readonly stackTrace: string[] | undefined

    constructor(timestamp: number, message: string, errorCode: string, stackTrace: string[] | undefined, versionNo: string = '2.0') {
        super(timestamp, versionNo, PayloadType.ErrorPayload)
        this.message = message
        this.errorCode = errorCode
        this.stackTrace = stackTrace
    }
}

export class DiagnosticPayload extends FablecastServicePayload {
    readonly round: Round
    readonly mode: RoundMode

    constructor(timestamp: number, round: Round, mode: RoundMode, versionNo: string = '2.0', tokenCosts?: TokenCosts) {
        super(timestamp, versionNo, PayloadType.DiagnosticPayload, tokenCosts)
        this.round = round
        this.mode = mode
    }
}

export class ControlPayload extends FablecastServicePayload {
    readonly action: ControlAction
    readonly content: string | undefined

    constructor(timestamp: number, action: ControlAction, message: string | undefined, versionNo: string = '2.0') {
        super(timestamp, versionNo, PayloadType.ControlPayload)
        this.action = action
        this.content = message
    }
}

export class DialogAudioMetadata extends FablecastServicePayload {
    readonly audioStreamId: string
    readonly encoding: string
    readonly frameCount: number

    constructor(timestamp: number, audioStreamId: string, encoding: string, frameCount: number, versionNo: string = '2.0') {
        super(timestamp, versionNo, PayloadType.DialogAudioMetadata)
        this.audioStreamId = audioStreamId
        this.encoding = encoding
        this.frameCount = frameCount
    }
}

export class DialogAudioFrame extends FablecastServicePayload {
    readonly blob: string
    readonly audioStreamId: string
    readonly frameNumber: number

    constructor(timestamp: number, blob: string, audioStreamId: string, frameNumber: number, versionNo: string = '2.0') {
        super(timestamp, versionNo, PayloadType.DialogAudioFrame)
        this.blob = blob
        this.audioStreamId = audioStreamId
        this.frameNumber = frameNumber
    }
}

export class Round {
    readonly previous: string | undefined
    readonly dialogEvents: DialogEvent[]

    constructor(previous: string | undefined, dialogEvents: DialogEvent[]) {
        this.previous = previous
        this.dialogEvents = dialogEvents
    }
}

export type SpeakerRef = {id: string, name: string | undefined}

export class Turn extends DialogEvent {
    readonly speaker: SpeakerRef
    readonly addressee: SpeakerRef
    readonly message: string
    readonly semantics: string[]
    readonly audioStreamId: string | undefined

    constructor(id: string, speaker: SpeakerRef, addressee: SpeakerRef, message: string, semantics: string[], audioStreamId: string | undefined) {
        super(id, DialogEventType.Turn)
        this.speaker = speaker
        this.addressee = addressee
        this.message = message
        this.semantics = semantics
        this.audioStreamId = audioStreamId
    }
}

export type TimedRound = {timestamp: number, round: Round}
export type TimedDialogEvent = {timestamp: number, event: DialogEvent}

export class RawHumanTurn extends DialogEvent {
    readonly speakerId: string
    readonly message: string

    constructor(id: string, speakerId: string, message: string) {
        super(id, DialogEventType.RawHumanTurn)
        this.speakerId = speakerId
        this.message = message
    }
}

export class PoiseToSpeak extends DialogEvent {
    readonly speakerId: string

    constructor(id: string, speakerId: string) {
        super(id, DialogEventType.PoiseToSpeak)
        this.speakerId = speakerId
    }
}


export class UnpoiseToSpeak extends DialogEvent {
    readonly speakerId: String

    constructor(id: string, speakerId: string) {
        super(id, DialogEventType.UnpoiseToSpeak)
        this.speakerId = speakerId
    }
}

export class ExpectResponse extends DialogEvent {
    readonly speakerId: String

    constructor(id: string, speakerId: string) {
        super(id, DialogEventType.ExpectResponse)
        this.speakerId = speakerId
    }
}


export class Pause extends DialogEvent {
    readonly duration: number

    constructor(id: string, duration: number) {
        super(id, DialogEventType.Pause)
        this.duration = duration
    }
}

export class NarrativeEventReference extends DialogEvent {
    readonly narrativeEventType: string

    constructor(id: string, narrativeEventType: string) {
        super(id, DialogEventType.NarrativeEventReference)
        this.narrativeEventType = narrativeEventType
    }
}


export class ProspectiveDialogRoundPayload extends FablecastServicePayload {
    readonly round: Round

    constructor(timestamp: number, round: Round, versionNo: string = '2.0') {
        super(timestamp, versionNo, PayloadType.ProspectiveDialogRound)
        this.round = round
    }
}

export class FinalizedDialogRoundPayload extends FablecastServicePayload {
    readonly round: Round

    constructor(timestamp: number, round: Round, versionNo: string = '2.0') {
        super(timestamp, versionNo, PayloadType.FinalizedDialogRound)
        this.round = round
    }
}

function readDialogEvent(obj: any): DialogEvent {

    if (!obj.type) {
        throw new Error('Invalid DialogEvent: missing type field')
    }

    switch (obj.type) {
        case DialogEventType.Turn:
            return new Turn(obj.id, obj.speaker, obj.addressee, obj.message, obj.semantics, obj.audioStreamId)
        case DialogEventType.PoiseToSpeak:
            return new PoiseToSpeak(obj.id, obj.speakerId)
        case DialogEventType.RawHumanTurn:
            return new RawHumanTurn(obj.id, obj.speakerId, obj.message)
        case DialogEventType.UnpoiseToSpeak:
            return new UnpoiseToSpeak(obj.id, obj.speakerId)
        case DialogEventType.ExpectResponse:
            return new ExpectResponse(obj.id, obj.speakerId)
        case DialogEventType.Pause:
            return new Pause(obj.id, obj.duration)
        case DialogEventType.NarrativeEventReference:
            return new NarrativeEventReference(obj.id, obj.narrativeEventType)
        default:
            console.error(JSON.stringify(obj))
            throw new Error('Invalid DialogEvent: unknown type')
    }
}


function readRound(obj: any): Round {
    //@ts-ignore
    return new Round(obj.previous === null ? undefined : obj.previous, obj.dialogEvents.map(e => readDialogEvent(e)))
}

export function readPayload(json: string): FablecastServicePayload {
    const obj = JSON.parse(json)

    if (!obj.payloadType) {
        throw new Error('Invalid message: missing payloadType field')
    }

    switch (obj.payloadType) {
        case PayloadType.ProspectiveDialogRound:
            return new ProspectiveDialogRoundPayload(obj.timestamp, readRound(obj.round), obj.versionNo)
        case PayloadType.FinalizedDialogRound:
            return new FinalizedDialogRoundPayload(obj.timestamp, readRound(obj.round), obj.versionNo)
        case PayloadType.DialogAudioMetadata:
            return new DialogAudioMetadata(obj.timestamp, obj.audioStreamId, obj.encoding, obj.frameCount, obj.versionNo)
        case PayloadType.DialogAudioFrame:
            return new DialogAudioFrame(obj.timestamp, obj.blob, obj.audioStreamId, obj.frameNumber, obj.versionNo)
        case PayloadType.ErrorPayload:
            return new ErrorPayload(obj.timestamp, obj.message, obj.errorCode, obj.stackTrace, obj.versionNo)
        case PayloadType.ControlPayload:
            return new ControlPayload(obj.timestamp, obj.action, obj.message, obj.versionNo)
        case PayloadType.DiagnosticPayload:
            return new DiagnosticPayload(obj.timestamp, obj.round, obj.mode, obj.versionNo)
    default:
            console.error(json)
            throw new Error('Invalid message: unknown messageType')
    }

}

export function writePayload(message: FablecastServicePayload): string {
    return JSON.stringify(message, (k, v) => {
        if (v === undefined) {
            return null;
        } else {
            return v;
        }
    });
}