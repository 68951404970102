import * as React from 'react';
import MessagesPane from './MessagesPane';
import { Card, Modal, ModalClose, ModalDialog, Sheet, Typography } from '@mui/joy';
import Grid from '@mui/joy/Grid';

import { v4 } from 'uuid';
import FablecastController from '../controller/FablecastController';
import DiagnosticsView from './Diagnostics';
import { DiagnosticPayload, DialogEvent, DialogEventType, ErrorPayload, FablecastServicePayload,  RawHumanTurn, Round, TimedRound, Turn, ControlAction, PoiseToSpeak, UnpoiseToSpeak } from '../controller/FablecastMessage';

export function FablecastLogin() {
  localStorage.setItem("fablecastIdentityToken", "")
  const queryString = window.location.hash;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get('access_token') || urlParams.get("#access_token")
  if (token === null) {
    const errorDesc = urlParams.get('error_description');
    return (
      <Sheet
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'background.level1',
          p: 4
        }}
      >
        <Card variant='outlined'>{errorDesc}</Card>
      </Sheet>
    );
  } else {
    localStorage.setItem("fablecastIdentityToken", token)
    window.close();
    return(<></>)
  }

}

export default function Fablecast() {

  const [previousRounds, setPreviousRounds] = React.useState<TimedRound[]>([]);
  const [diagnostics, setDiagnostics] = React.useState<DiagnosticPayload[]>([]);
  const [currentDiagnostic, setCurrentDiagnostic] = React.useState<DiagnosticPayload>();
  const [roundTicker, setRoundTicker] = React.useState<NodeJS.Timer>();
  const [identityToken, setIdentityToken] = React.useState<string>("");
  const [error, setError] = React.useState<ErrorPayload>();
  const [isPoised, setIsPoised] = React.useState<Boolean>(false);
  const [shouldBePoised, setShouldBePoisedPoised] = React.useState<Boolean>(false);
  const [unPoiseTimer, setUnPoiseTimer] = React.useState<NodeJS.Timeout>();

  const [currentRoundEvents, setCurrentRoundEvents] = React.useState<DialogEvent[]>([]);
  const [currentRoundTime, setCurrentRoundTime] = React.useState<number>(0);

  async function onSendControl(action: ControlAction, messageBody: string | undefined) {   
    await FablecastController.sendControlMessage(action, messageBody);
  };

  const handlePayload = (message: FablecastServicePayload) => {
    if (message instanceof ErrorPayload) {
      FablecastController.disconnect(() => {
        setError(message);
      })
    } else if (message instanceof DiagnosticPayload) {
      setDiagnostics(d => [...d, message])
    }
  };

  React.useEffect(() => {
    if (!shouldBePoised && isPoised && currentRoundEvents.length === 0) {
        const newId = v4()
        const round = new Round(FablecastController.getLatestEventID(), [...currentRoundEvents, new UnpoiseToSpeak(newId, "Player1")])
        setPreviousRounds(pr => [...pr, {timestamp: Date.now(), round}])
        setCurrentRoundEvents([]);
        setIsPoised(false);
        FablecastController.sendFinalizedRound(round).then(() => {});
    }
  }, [shouldBePoised, isPoised, currentRoundEvents])

  const handleRound = (round: Round, timestamp: number) => {
    var currentRoundEvents: DialogEvent[] = [];
    setCurrentRoundEvents(currentRoundEvents);
    setCurrentRoundTime(Date.now())
    var eventIdx = 0;
    const interval = setInterval(() => {
      
      console.log("Tick")
      if (eventIdx >= round.dialogEvents.length) {
        
        console.log("Ticked Past End Of Round")
        console.log(round.previous)
        console.log(FablecastController.getLatestEventID())
        if (round.previous === FablecastController.getLatestEventID()) {
          FablecastController.sendFinalizedRound(round).then(() => {});
          setPreviousRounds(pr => [...pr, {timestamp, round}])
          setCurrentRoundEvents([]);
        }

        clearInterval(interval);
        return;
      }
      

      const event = round.dialogEvents[eventIdx];
      
      if (eventIdx > currentRoundEvents.length - 1) {
        if (event.type === DialogEventType.Turn) {
          currentRoundEvents.push(new Turn(v4(),
            (event as Turn).speaker, (event as Turn).addressee, "",  (event as Turn).semantics, (event as Turn).audioStreamId
          ))
        } else {
          currentRoundEvents.push(event)
        }
      } else {
        const existingTurn = currentRoundEvents[eventIdx]        
        const finished = (existingTurn.type !== DialogEventType.Turn || (existingTurn as Turn).message.length === (event as Turn).message.length)
        if (finished) {
          eventIdx += 1
        } else {
          currentRoundEvents = [...currentRoundEvents.filter(r => r !== existingTurn), new Turn(v4(),
            (event as Turn).speaker, (event as Turn).addressee, (event as Turn).message.substring(0, (existingTurn as Turn).message.length + 1),  (event as Turn).semantics, (event as Turn).audioStreamId
          )]
        }
      }

      setCurrentRoundEvents(currentRoundEvents);

    }, 20);
    setRoundTicker(interval);
  };

  async function onSend(messageBody: string) {
    const newId = v4()
    const round = new Round(FablecastController.getLatestEventID(), [...currentRoundEvents, new RawHumanTurn(newId, "Player1", messageBody)])

    if (roundTicker !== undefined) {
      clearInterval(roundTicker);
    }
  

    console.log("Sending New User Round")
    FablecastController.sendFinalizedRound(round!).then(() => {});
    setPreviousRounds(pr => [...pr, {timestamp: Date.now(), round}])
    setShouldBePoisedPoised(false)
    setIsPoised(false)
    setCurrentRoundEvents([]);

  };

  async function onStartTalking() {
    
    if (unPoiseTimer !== undefined) {
      clearTimeout(unPoiseTimer!)
    }
    setUnPoiseTimer(setTimeout(() => {
      setShouldBePoisedPoised(false)
    }, 2000))
    
    if (!shouldBePoised) {
      setShouldBePoisedPoised(true)

      if (!isPoised) {
        if (roundTicker !== undefined) {
          clearInterval(roundTicker);
          setRoundTicker(undefined);
        }

        const newId = v4()
        const round = new Round(FablecastController.getLatestEventID(), [...currentRoundEvents, new PoiseToSpeak(newId, "Player1")])
        
        console.log("Sending User Interruption")
        FablecastController.sendFinalizedRound(round!).then(() => {});
        setIsPoised(true)
        setPreviousRounds(pr => [...pr, {timestamp: Date.now(), round}])
        setCurrentRoundEvents([]);
      }
    }
  };

  React.useEffect(() => {
    FablecastController.addPaylodListener(handlePayload);
    FablecastController.addRoundListener(handleRound);
    return () => {
      FablecastController.clearPayloadListener(handlePayload);
      FablecastController.clearRoundListener(handleRound);
    }
  }, []);

  const messagePane = (<MessagesPane
    identityToken={identityToken}
    setIdentityToken={setIdentityToken}
    clear={() => {
      setPreviousRounds([])
      setCurrentRoundEvents([])
      FablecastController.clearPending();
      if (roundTicker !== undefined) {
        clearInterval(roundTicker)
      }
    }}
    dialogEvents={[...previousRounds.flatMap(r => r.round.dialogEvents.map(e => ({timestamp: r.timestamp, event: e}))), ...currentRoundEvents.map(e => ({timestamp: Date.now(), event: e}))]}
    currentRoundTime={currentRoundTime}
    diagnostics={diagnostics}
    setDiagnostics={setDiagnostics}
    onSend={onSend}
    onSendControl={onSendControl}
    onStartTalking={onStartTalking}
    error={error}
    setError={setError}
    setCurrentDiagnostic={setCurrentDiagnostic}
  />);

  return (<>
    
    
    {currentDiagnostic === undefined ? messagePane : (
      <Grid container spacing={2} sx={{ display: 'flex', p: 0, m: 0}}>
        <Grid sx={{ flex: 2, maxHeight: '100%', overflow: 'hidden', p: 0, m: 0}}>
          {messagePane}
        </Grid>
        <Grid sx={{ flex: 1, p: 4, m: 0}}>
          <DiagnosticsView diagnostic={currentDiagnostic} diagnostics={diagnostics} setCurrentDiagnostic={setCurrentDiagnostic} />
        </Grid>
      </Grid>
    )}

    <Modal open={error !== undefined} onClose={() => {setError(undefined)}}>
      <ModalDialog
        color="danger"
        layout="center"
        variant="solid"
      >
        <ModalClose />
        <Typography level={"h4"}>{error?.message}</Typography>
        {error?.stackTrace !== undefined && (
          <Sheet sx={{p: 2, borderRadius: 8}}>
            {error?.stackTrace?.map(t => (
              <pre style={{margin: 0, padding: 0}}>{t}</pre>
            ))}
          </Sheet>
      )}
      </ModalDialog>
    </Modal>
  </>
  );
}
