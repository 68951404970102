import * as React from 'react';
import { Box, Breadcrumbs, Button, ButtonGroup, Checkbox, Chip, CircularProgress, CircularProgressProps, Grid, IconButton, Sheet, Snackbar, Stack, Table, Textarea, Tooltip, Typography } from '@mui/joy';
import { useLoaderData, useNavigate } from 'react-router-dom';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import RefreshIcon from '@mui/icons-material/Refresh';
import TimerIcon from '@mui/icons-material/Timer';
import SnoozeIcon from '@mui/icons-material/Snooze';

function renderDuration(timeMillis: number): string {
  // Example format: 4m, 30s, 2h
  const hours = Math.floor(timeMillis / 3600000);
  const hoursRounded = Math.round(timeMillis / 3600000);
  const minutes = Math.floor(timeMillis / 60000);
  const minutesRounded = Math.floor(timeMillis / 60000);
  const seconds = Math.floor((timeMillis % 60000) / 1000);
  if (hoursRounded > 99) {
    return "+"
  } else if (hours > 0) {
    return `${hoursRounded}h`;
  } else if (minutes > 0) {
    return `${minutesRounded}m`;
  } else {
    return `${seconds}s`;
  }
}

function ExpiryTimer(
  props: CircularProgressProps & { budget: number, remaining: number },
) {
  const percentage = Math.min(100*(props.remaining / props.budget), 100);
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress determinate  {...props} value={percentage} color={percentage < 10 ? 'danger' : undefined}/>
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
        >{renderDuration(props.remaining)}</Typography>
      </Box>
    </Box>
  );
}

export interface PuppetMainProps {
  jobQueueId: string;
};

export async function puppetLoader({ params } : any): Promise<PuppetMainProps> {
  return {
    jobQueueId: params.jobQueueId
  };
}

interface JobDetails {
  jobId: string;
  originalTimeBudget: number;
  timeToExpiry: number;
  snoozable: boolean;
  status: string;
  clientMetadata?: Record<string, string>;
}

interface Job {
	details: JobDetails,
	completionData: {
    inputData: any,
    humanReadableRendering?: string,
		completion: string
	}
}

export default function PuppetMain(props: { puppetEndpoint: string; puppetKey: string}) {

  const data = useLoaderData() as PuppetMainProps;
  const navigate = useNavigate();

  const [selectedJob, setSelectedJob] = React.useState<Job>()

  const [rows, setRows] = React.useState<JobDetails[]>([]);
  const [refreshInterval, setRefreshInterval] = React.useState<number>(5000);
  const [autorefreshJob, setAutorefreshJob] = React.useState<NodeJS.Timer>();
  const [correctedCompletion, setCorrectedCompletion] = React.useState<string>("");
  const [errors, setErrors] = React.useState<{id: number, message: string}[]>([]);

  const refreshJobs: () => void = React.useCallback(() => {
      fetch(`${props.puppetEndpoint}/v1/jobs/${data.jobQueueId}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "x-api-key": props.puppetKey
        }
      })
      .then(res => res.json())
      .then(res => setRows(res.jobs))
      .catch(err => error(err.message))
    }, [data, props]);

  function updateRefreshInterval(n: number) {
    setRefreshInterval(n)
    clearInterval(autorefreshJob);
    setAutorefreshJob(setInterval(refreshJobs, n));
  }

  function error(message: string) {
    setErrors(es => [...es, {id: Math.max(...es.map(e => e.id)) + 1, message}])
  }
  
  React.useEffect(() => {
    let jobQueueIds = JSON.parse(localStorage.getItem('jobQueueIds') || "[]") as string[];
    localStorage.setItem('jobQueueIds', JSON.stringify(
      [ data.jobQueueId,
        ...jobQueueIds.filter(jobQueueId => jobQueueId !== data.jobQueueId)
      ]));
    refreshJobs();
  }, [data, refreshJobs]);

  React.useEffect(() => {
    const counter = setInterval(() => {
      setRows(rows => rows.map(row => {
        return {
          ...row,
          timeToExpiry: row.timeToExpiry - 100
        }
      }).filter(row => row.timeToExpiry > 0))
    }, 100)
    return () => clearInterval(counter)
  }, [rows, setRows]);

  function snoozeJob(jobId: string) {
    fetch(`${props.puppetEndpoint}/v1/jobs/${jobId}/snooze`, {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
          "x-api-key": props.puppetKey
      }
    })
    .then(() => refreshJobs())
    .catch(err => error(err.message))
  }

  function getJob(jobId: string) {
    fetch(`${props.puppetEndpoint}/v1/jobs/${jobId}`, {
      method: "GET",
      headers: {
          "Content-Type": "application/json",
          "x-api-key": props.puppetKey
      }
    })
    .then(res => res.json().then(j => j as Job))
    .then(res => {
      setCorrectedCompletion(res.completionData.completion)
      return res;
    })
    .then(res => setSelectedJob(res))
    .catch(err => error(err.message))
  }

  function sendCompletion() {
    fetch(`${props.puppetEndpoint}/v1/jobs/${selectedJob!.details.jobId}`, {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
          "x-api-key": props.puppetKey
      },
      body: JSON.stringify({
        correctedCompletion
      })
    })
    .then(() => setSelectedJob(undefined))
    .catch(err => error(err.message))
  }

  function renderInput(inputData: any): string {
    if (typeof inputData === "string") {
      return inputData;
    }
    return JSON.stringify(inputData, null, 2);
  }

  return (
    <Box sx={{ flexGrow: 1, backgroundColor: 'background.level1', padding: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRoundedIcon  />}
          sx={{ pl: 0, flex: 1 }}
        >
          <IconButton onClick={() => navigate('/puppet')}><ChecklistRtlIcon /></IconButton>
          <Typography>{data.jobQueueId}</Typography>
        </Breadcrumbs>
      </Box>
      <Grid container sx={{ flexGrow: 1, backgroundColor: 'background.level1' }} spacing={2}>
        <Grid lg={4} md={12} xs={12}>
          <Sheet
            variant="outlined"
            sx={{ width: '100%', boxShadow: 'sm', borderRadius: 'sm' }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                py: 1,
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                borderTopLeftRadius: 'var(--unstable_actionRadius)',
                borderTopRightRadius: 'var(--unstable_actionRadius)',
              }}
            >
              <Typography
                level="body-lg"
                sx={{ flex: '1 1 100%' }}
                id="tableTitle"
                component="div"
              >
                Pending Jobs
              </Typography>
              {
                autorefreshJob === undefined ? (
                  <Tooltip title="Refresh">
                    <IconButton size="sm" color="primary" variant="solid" onClick={() => refreshJobs()}>
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <ButtonGroup size='sm'>
                    <Button onClick={() => updateRefreshInterval(1000)} variant={refreshInterval === 1000 ? 'solid' : undefined} color={refreshInterval === 1000 ? 'primary' : undefined}>1s</Button>
                    <Button onClick={() => updateRefreshInterval(5000)} variant={refreshInterval === 5000 ? 'solid' : undefined} color={refreshInterval === 5000 ? 'primary' : undefined}>5s</Button>
                    <Button onClick={() => updateRefreshInterval(30000)} variant={refreshInterval === 30000 ? 'solid' : undefined} color={refreshInterval === 30000 ? 'primary' : undefined}>30s</Button>
                  </ButtonGroup>
                )
              }
              <Checkbox
                label={<Typography level='body-xs'>Auto&nbsp;Refresh</Typography>}
                sx={{ml: 1}}
                checked={autorefreshJob !== undefined}
                onChange={
                  (event) => {
                    if (autorefreshJob === undefined) {
                      setAutorefreshJob(setInterval(refreshJobs, 2000));
                    } else {
                      clearInterval(autorefreshJob);
                      setAutorefreshJob(undefined);
                    }
                  }
                }
              />
            </Box>
            <Table
              aria-labelledby="tableTitle"
              hoverRow
              sx={{
                '--TableCell-headBackground': 'transparent',
                '--TableCell-selectedBackground': (theme) =>
                  theme.vars.palette.success.softBg
              }}
            >
            <thead>
              <tr style={{width: '100%'}}>
                <th style={{width: '75%'}}>
                  <Typography
                    color="neutral"
                    textColor='primary.plainColor'
                    fontWeight="lg"
                  >
                    JobId
                  </Typography>
                </th>
                <th style={{width: '25%', textAlign: 'center'}}>
                  <TimerIcon color='primary'/>
                </th>
              </tr>
            </thead>
              <tbody>
                {rows
                  .map((row) => {
                    const isItemSelected = (selectedJob?.details.jobId === row.jobId);

                    return (
                      <tr
                        onClick={() => {
                          getJob(row.jobId)
                        }}
                        key={row.jobId}
                        style={
                          isItemSelected
                            ? ({
                                '--TableCell-dataBackground':
                                  'var(--TableCell-selectedBackground)',
                                '--TableCell-headBackground':
                                  'var(--TableCell-selectedBackground)',
                                cursor: 'pointer'
                              } as React.CSSProperties)
                            : {cursor: 'pointer'}
                        }
                      >
                        <td>
                          {row.jobId}
                          {row.clientMetadata !== undefined && (
                            <Grid container sx={{overflow: 'auto', maxHeight: 56}}>
                              {
                                Object.entries(row.clientMetadata).map((item, index) => (
                                  <Chip
                                    variant="outlined"
                                    color="neutral"
                                    key={index}
                                    size="lg"
                                    startDecorator={(
                                      <Typography
                                        textColor="primary.plainColor"
                                        fontWeight="lg"
                                      >
                                        {item[0]}
                                      </Typography>
                                    )}
                                  >
                                    {item[1]}
                                  </Chip>
                                ))
                              }
                            </Grid>
                          )}
                        </td>
                        <td style={{textAlign: 'center'}}>
                            <Grid container alignItems="center" justifyContent="center">
                              <ExpiryTimer remaining={row.timeToExpiry} budget={row.originalTimeBudget}/>
                              {row.snoozable && (
                                <IconButton size='sm' sx={{m: 0.5}} onClick={(e) => {
                                    e.stopPropagation();
                                    snoozeJob(row.jobId)
                                  }}>
                                  <SnoozeIcon/>
                                </IconButton>
                              )}
                            </Grid>
                        </td>
                      </tr>
                    );
                  })}
                  {rows.length === 0 && (
                    <tr
                      onClick={() => refreshJobs()}
                      style={{cursor: 'pointer'}}
                    >
                      <td colSpan={2}>
                        <Grid container alignItems="center" justifyContent="center">
                          No Pending Jobs
                          <RefreshIcon/>
                        </Grid>
                      </td>
                    </tr>
                  )}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={6}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        <IconButton
                          size="sm"
                          color="neutral"
                          variant="outlined"
                          disabled={true}
                          onClick={() => {}}
                          sx={{ bgcolor: 'background.surface' }}
                        >
                          <KeyboardArrowLeftIcon />
                        </IconButton>
                        <IconButton
                          size="sm"
                          color="neutral"
                          variant="outlined"
                          disabled={false}
                          onClick={() => {}}
                          sx={{ bgcolor: 'background.surface' }}
                        >
                          <KeyboardArrowRightIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  </td>
                </tr>
              </tfoot>
            </Table>
          </Sheet>
        </Grid>
        <Grid lg={8} md={12} xs={12}>
          <Box sx= {{maxHeight: '100dvh', overflow: 'auto'}}>
            {selectedJob !== undefined && (
              <Stack spacing={1}>
                <Typography level='body-md'>Input</Typography>
                <Textarea
                  readOnly={true}
                  variant='soft'
                  value={selectedJob.completionData.humanReadableRendering || renderInput(selectedJob.completionData.inputData)}
                  minRows={3}
                  maxRows={25}/>

                <Typography level='body-md'>Completion</Typography>
                <Textarea
                  value={correctedCompletion}
                  onChange={(e) => setCorrectedCompletion(e.target.value)}
                  minRows={3}
                  maxRows={25}/>

                <Button onClick={() => sendCompletion()}>Send</Button>
              </Stack>
            )}
          </Box>
        </Grid>
      </Grid>
      {
        errors.map(e => (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={true}
            color="danger"
            variant="solid"
            onClose={() => setErrors(es => es.filter(e2 => (e2.id !== e.id)))}
            endDecorator={
              <Button
                onClick={() => setErrors(es => es.filter(e2 => (e2.id !== e.id)))}
                size="sm"
                variant="soft"
                color="danger"
              >
                Dismiss
              </Button>
            }
          >
            {e.message}
          </Snackbar>
        ))
      }

    </Box>
  );
}
