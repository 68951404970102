import { ControlAction, ControlPayload, DialogAudioFrame, FablecastServicePayload, FinalizedDialogRoundPayload, ProspectiveDialogRoundPayload, readPayload, Round, TimedRound, writePayload } from "./FablecastMessage";

interface AudioFrame {
    blob: string;
    frameNumber: number;
}

class IFablecastController {

    private endpoint: string = localStorage.getItem('fablecastEndpoint') || "wss://test.api.fablecast.ai/wss";
    private websocket: WebSocket | undefined = undefined;

    private roundListeners: ((round: Round, timestamp: number) => void)[] = [];
    private payloadListeners: ((message: FablecastServicePayload) => void)[] = [];
    private isConnected: boolean = false;
    private audioStreams: Record<string, AudioFrame[]> = {};
    private pendingRounds: TimedRound[] = [];
    private lastEventID: string | undefined = undefined;

    public connect(token:string, callback: () => void) {
        if (this.websocket !== undefined) {
            this.websocket.close();
        }
        this.isConnected = false;
        this.websocket = new WebSocket(this.endpoint, ["FCP1", token]);
        this.websocket.addEventListener("open", () => {
            this.isConnected = true;
            callback();
        });
        this.websocket.addEventListener("message", event => {
            try {
                this.handlePayload(readPayload(event.data));
            } catch (e) {
                console.error(e)
            }
        });
    }

    public disconnect(callback: () => void) {
        if (this.websocket !== undefined) {
            this.websocket.close();
        }
        this.isConnected = false;
        this.audioStreams = {};
        this.pendingRounds = [];
        this.lastEventID = undefined;
        callback();
    }

    public connected() {
        return this.isConnected
    }

    
    public addPaylodListener(listener: (message: FablecastServicePayload) => void) {
        this.payloadListeners.push(listener);
    }
    
    public addRoundListener(listener: (round: Round, timestamp: number) => void) {
        this.roundListeners.push(listener);
    }
    
    public clearPayloadListener(listener: (message: FablecastServicePayload) => void) {
        this.payloadListeners = this.payloadListeners.filter(l => l !== listener);
    }

    public clearRoundListener(listener: (message: Round, timestamp: number) => void) {
        this.roundListeners = this.roundListeners.filter(l => l !== listener);
    }

    public clearPending() {
        this.pendingRounds = [];
    }

    private handlePayload(message: FablecastServicePayload) {
        if (message instanceof DialogAudioFrame) {
            const frame = message as DialogAudioFrame
            if (!this.audioStreams[frame.audioStreamId]) {
                this.audioStreams[frame.audioStreamId] = [];
            }
            this.audioStreams[frame.audioStreamId][frame.frameNumber] = {
                blob: frame.blob,
                frameNumber: frame.frameNumber,
            };
        }
        if (message instanceof ProspectiveDialogRoundPayload) {
            if (this.pendingRounds.length === 0 || this.lastEventID === message.round.previous || this.pendingRounds.map(r => r.round.dialogEvents[r.round.dialogEvents.length - 1].id).includes(message.round.previous || 'NONE')) {
                this.pendingRounds.push({round: message.round, timestamp: message.timestamp});
                if (this.pendingRounds.length === 1 && (this.pendingRounds[0].round.previous === this.lastEventID || this.lastEventID === undefined )) {
                    this.roundListeners.forEach(l => l(message.round, message.timestamp));
                }
            }
        }
        this.payloadListeners.forEach(l => l(message));
    }

    public getLatestEventID(): string | undefined {
        return this.lastEventID;
    }

    async playAudio(audioStreamId: string): Promise<void> {
        const frames = this.audioStreams[audioStreamId];
        const sortedFrames = frames.filter(Boolean).sort((a, b) => a.frameNumber - b.frameNumber);
        const audioBlobs = sortedFrames.map(frame => this.base64ToBlob(frame.blob, 'audio/mp3'));
        const audioBlob = new Blob(audioBlobs, { type: 'audio/mp3' });

        const audioUrl = URL.createObjectURL(audioBlob);
        const audio = new Audio(audioUrl);

        try {
            await audio.play();
        } catch (error) {
            console.error(error);
        } finally {
            audio.onended = () => URL.revokeObjectURL(audioUrl);
        }
    }

    private base64ToBlob(base64: string, mimeType: string): Blob {
        const byteCharacters = atob(base64);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: mimeType });
    }

    async sendFinalizedRound(round: Round): Promise<void> {
        this.lastEventID = round.dialogEvents[round.dialogEvents.length - 1].id

        await this.websocket?.send(writePayload(new FinalizedDialogRoundPayload(
            Date.now(),
            round
        )));
        
        const legitimates: (string | undefined)[] = [this.lastEventID]
        const newPendingRounds: TimedRound[] = [];
        this.pendingRounds.forEach(pr => {
            if (legitimates.includes(pr.round.previous)) {
                newPendingRounds.push(pr)
                legitimates.push(pr.round.dialogEvents[pr.round.dialogEvents.length - 1].id)
            }
        });
        this.pendingRounds = newPendingRounds;
        if (this.pendingRounds.length > 0) {
            this.roundListeners.forEach(l => l(this.pendingRounds[0].round, this.pendingRounds[0].timestamp));
        }
    }

    async sendControlMessage(action: ControlAction, messageInput: string | undefined): Promise<void> {
        this.websocket?.send(writePayload(new ControlPayload(
            Date.now(),
            action,
            messageInput
        )));
    }

    public getEndpoint() : string {
        return this.endpoint;
    }
    public setEndpoint(endpoint: string) {
        this.endpoint = endpoint;
    }
}

const FablecastController = new IFablecastController();
export default FablecastController;