import * as React from 'react';
import Box from '@mui/joy/Box';
import Sheet from '@mui/joy/Sheet';
import Stack from '@mui/joy/Stack';
import ChatBubble from './ChatBubble';
import MessageInput from './MessageInput';
import { Button } from '@mui/joy';
import { ControlAction, DiagnosticPayload, DialogEventType, ErrorPayload, RawHumanTurn, TimedDialogEvent, Turn } from '../controller/FablecastMessage';
import InternalMessageBubble from './InternalMessageBubble';

type MessagesPaneProps = {
  identityToken: string,
  setIdentityToken: (value: string) => void
  dialogEvents: TimedDialogEvent[],
  currentRoundTime: number,
  clear: () => void,
  onSend: (message: string) => Promise<void>,
  onSendControl: (action: ControlAction, message: string) => Promise<void>,
  error: ErrorPayload | undefined
  setError: (error: ErrorPayload | undefined) => void
  onStartTalking: () => void
  diagnostics: DiagnosticPayload[]
  setDiagnostics: React.Dispatch<React.SetStateAction<DiagnosticPayload[]>>;
  setCurrentDiagnostic: React.Dispatch<React.SetStateAction<DiagnosticPayload | undefined>>;
};

export default function MessagesPane(props: MessagesPaneProps) {

  const {
    identityToken,
    setIdentityToken,
    dialogEvents,
    currentRoundTime,
    clear,
    onSend,
    onSendControl,
    error,
    setError,
    onStartTalking,
    diagnostics,
    setDiagnostics,
    setCurrentDiagnostic
  } = props;
  const [textAreaValue, setTextAreaValue] = React.useState('');
  const [annotationAreaValue, setAnnotationAreaValue] = React.useState('');
  const messagesEnd = React.useRef<HTMLDivElement>(null);

  const handleModalClose = async (annotationAreaValue: string) => {
    onSendControl(ControlAction.AnnotateGameLog, annotationAreaValue)
  };

  var prevTurnId: (RawHumanTurn & { speakerName: string | undefined }) | undefined = undefined
  var turns = dialogEvents
  .filter(e => e.event.type === DialogEventType.Turn || e.event.type === DialogEventType.RawHumanTurn)
  .map(e => {
    if (e.event.type === DialogEventType.RawHumanTurn) {
      return {timestamp: e.timestamp, rht: {...(e.event as RawHumanTurn), speakerName: undefined, audioStreamId: undefined}}
    } else {
      const t = e.event as Turn
      return {timestamp: e.timestamp, rht: {...new RawHumanTurn(t.id, t.speaker.id, t.message), speakerName: t.speaker.name, audioStreamId: t.audioStreamId}}
    }
  })
  
  return (
    <Sheet
      sx={{
        height: '100dvh',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'background.level1'
      }}
    >
      <Box
        sx={{ flex: 1, px: 2, py: 3, overflowY: 'scroll'}}
      >
        <Stack spacing={2} justifyContent="flex-end">
          {
            turns.map((turn: {timestamp: number, rht: (RawHumanTurn & {speakerName: string | undefined, audioStreamId: string | undefined})}, index: number) => {
                const isYou = false;
                const startTime = (prevTurnId === undefined) ? 0 : turns.filter( t => t.rht === prevTurnId!)[0].timestamp
                prevTurnId = turn.rht
                const endTime = Math.min(turn.timestamp, currentRoundTime)

                const preceedingDiagnostics: DiagnosticPayload[] = diagnostics.filter(d => d.timestamp > startTime && d.timestamp <= endTime)
                return (<>
                  <div>
                    {preceedingDiagnostics.map(d => (<InternalMessageBubble diagnostic={d} setCurrentDiagnostic={setCurrentDiagnostic}/>))} 
                  </div>
                  <Stack
                    key={index}
                    direction="row"
                    spacing={2}
                    flexDirection={isYou ? 'row-reverse' : 'row'}
                  >
                    
                    <ChatBubble turn={turn.rht} diagnostic={undefined} setCurrentDiagnostic={setCurrentDiagnostic}/>
                  </Stack>
                </>
                );
              })
          }<div>
          {
              dialogEvents.length > 0 && diagnostics.filter(d => d.timestamp > currentRoundTime)
              .map((d, index) => (<InternalMessageBubble diagnostic={d} setCurrentDiagnostic={setCurrentDiagnostic}/>)
              )
          }</div>
        </Stack>
        { dialogEvents.length !== 0 && (
          <Box sx={{width: '100%', alignContent: 'center', alignItems: 'center', textAlign: 'center', mt: 1}}>
            <Button variant='soft' size='sm' onClick={() => {
              clear()
              setDiagnostics([]);
            }}>Clear History</Button>
          </Box>
        )}
        <div ref={messagesEnd} style={{marginTop: 24}}>&nbsp;</div>
      </Box>
      <MessageInput
        textAreaValue={textAreaValue}
        setTextAreaValue={setTextAreaValue}
        identityToken={identityToken}
        setIdentityToken={setIdentityToken}
        onStartTalking={onStartTalking}
        onSubmit={() => onSend(textAreaValue).then(() => messagesEnd.current!.scrollIntoView({ behavior: "smooth" }))}
        error={error}
        setError={setError}
        annotationAreaValue={annotationAreaValue}
        setAnnotationAreaValue={setAnnotationAreaValue}
        onModalClose={() => handleModalClose(annotationAreaValue)}
      />
    </Sheet>
  );
}
