import * as React from 'react';
import { IconButton, Box, Chip, Input, List, Typography, Stack, Sheet, LinearProgress, Modal, Card, ButtonGroup, Button} from '@mui/joy';
import AddIcon from '@mui/icons-material/Add';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import NarrativeListingView, { NarrativeListing } from './NarrativeListing';
import { NarrativeEndpoints } from './Narratives';

type NarrativeListingsProps = {
  selectedListing: NarrativeListing | undefined
  setSelectedListing: React.Dispatch<React.SetStateAction<NarrativeListing | undefined>>
  narrativeEndpoints: NarrativeEndpoints
  userJwt: string
};

export default function NarrativeListings({selectedListing, setSelectedListing, narrativeEndpoints, userJwt}: NarrativeListingsProps) {

  const [listings, setListings] = React.useState<NarrativeListing[]>([])
  const [searching, setSearching] = React.useState<boolean>(true)
  const [searchString, setSearchString] = React.useState<string>("")
  const [createNewModalOpen, setCreateNewModalOpen] = React.useState(false);
  const [newID, setNewID] = React.useState("");
  const [newVersion, setNewVersion] = React.useState("");


  React.useEffect(() => {
    setSearching(true)
    fetch(`${narrativeEndpoints.narrativeSearchEndpoint}?search=${searchString}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${userJwt}`
      },
      mode: 'cors'
    })
    .then(res => res.json())
    .then(res => setListings(res.listings))
    .then(() => setSearching(false))
  }, [searchString, setSearching, setListings, selectedListing, userJwt, narrativeEndpoints])

  async function createNewVersion(narrativeId: string, narrativeVersion: string) {
    fetch(narrativeEndpoints.narrativeCreateEndpoint, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${userJwt}`
      },
      body: JSON.stringify({
        id: narrativeId,
        version: narrativeVersion
      }),
      mode: 'cors'
    })
    .then(() => {
      setSearching(true)
      fetch(`${narrativeEndpoints.narrativeSearchEndpoint}?search=${searchString}`, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': `Bearer ${userJwt}`
        },
        mode: 'cors'
      })
      .then(res => res.json())
      .then(res => setListings(res.listings))
      .then(() => setSearching(false))
    })
    .then(() => setCreateNewModalOpen(false))    
  }

  return (
    <Sheet
      sx={{
        borderRight: '1px solid',
        borderColor: 'divider',
        height: { sm: 'calc(100dvh - var(--Header-height))', md: '100dvh' },
        overflowY: 'auto',
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        sx={{ alignItems: 'center', justifyContent: 'space-between', p: 2, pb: 1.5 }}
      >
        <Typography
          component="h1"
          endDecorator={
            <Chip
              variant="soft"
              color="primary"
              size="md"
              slotProps={{ root: { component: 'span' } }}
            >
              {listings.length}
            </Chip>
          }
          sx={{ fontSize: { xs: 'md', md: 'lg' }, fontWeight: 'lg', mr: 'auto' }}
        >
          Narratives
        </Typography>
        <IconButton
          variant="plain"
          aria-label="edit"
          color="neutral"
          size="sm"
          sx={{ display: { xs: 'none', sm: 'unset' } }}
          onClick={() => {
            setNewID("")
            setNewVersion("")
            setCreateNewModalOpen(true)
          }}
        >
          <AddIcon />
        </IconButton>
        <Modal open={createNewModalOpen} onClose={() => setCreateNewModalOpen(false)}>
          <Box sx= {{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            p: 4}}>
            <Card variant="outlined">
              <Input placeholder="Narrative ID" value={newID} onChange={e => setNewID(e.target.value)}/>
              <Input placeholder="Version" value={newVersion} onChange={e => setNewVersion(e.target.value)}/>
              <ButtonGroup sx={{width: '100%'}} buttonFlex={1}>
                <Button onClick={() => setCreateNewModalOpen(false)}>Cancel</Button>
                <Button disabled={newID === "" || newVersion === ""} color='primary' variant='solid' onClick={() => createNewVersion(newID, newVersion)}>Create</Button>
              </ButtonGroup>
            </Card>
          </Box>
        </Modal>
      </Stack>
      <Box sx={{ px: 2, pb: 1.5 }}>
        <Input
          size="sm"
          startDecorator={<SearchRoundedIcon />}
          placeholder="Search"
          aria-label="Search"
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
        />
      </Box>
      <List
        sx={{
          py: 0,
          '--ListItem-paddingY': '0.75rem',
          '--ListItem-paddingX': '1rem',
        }}
      >
        {searching ? <LinearProgress /> : <div style={{height: '6px'}} />}
         {listings.map((listing) => (
          <NarrativeListingView
            listing={listing}
            selectedListing={selectedListing}
            setSelectedListing={setSelectedListing}
          />
        ))}
      </List>
    </Sheet>
  );
}
