import * as React from 'react';
import { DiagnosticPayload, DialogEvent, DialogEventType, ExpectResponse, NarrativeEventReference, PoiseToSpeak, RoundMode, SpeakerRef, Turn, UnpoiseToSpeak } from '../controller/FablecastMessage';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Card, Typography, CardContent } from '@mui/joy';

export interface DiagnosticsProps {
  diagnostic: DiagnosticPayload
  diagnostics: DiagnosticPayload[]
  setCurrentDiagnostic: React.Dispatch<React.SetStateAction<DiagnosticPayload | undefined>>;
}

function SpeakerLabel({speakerRef}: {speakerRef: SpeakerRef}) {
  return (<>{speakerRef.id} ({speakerRef.name || "Unnamed"})</>)
  
}

function Event({dialogEvent}: {dialogEvent: DialogEvent}) {
  switch(dialogEvent.type) {
    case DialogEventType.NarrativeEventReference:
      return (<></>)
    case DialogEventType.Turn:
      return (<>
        <div><Typography level="title-sm">Speaker</Typography> <SpeakerLabel speakerRef={(dialogEvent as Turn).speaker}/></div>
        <div><Typography level="title-sm">Addressee</Typography> <SpeakerLabel speakerRef={(dialogEvent as Turn).addressee}/></div>
        <div>&nbsp;</div>
        <div><Typography level="title-md">{(dialogEvent as Turn).message}</Typography></div>
        <div>&nbsp;</div>
        <div><Typography level="title-sm">Semantics</Typography></div>
        <div><pre>{(dialogEvent as Turn).semantics.join("\n")}</pre></div>
      </>)
    case DialogEventType.PoiseToSpeak:
      return (<Typography level="title-md">{(dialogEvent as PoiseToSpeak).speakerId}</Typography>)
    case DialogEventType.UnpoiseToSpeak:
        return (<Typography level="title-md">{(dialogEvent as UnpoiseToSpeak).speakerId}</Typography>)
    case DialogEventType.ExpectResponse:
        return (<Typography level="title-md">{(dialogEvent as ExpectResponse).speakerId}</Typography>)
        
    default:
      return (<pre>{JSON.stringify(dialogEvent, null, 2)}</pre>);
  }
  
}

export default function DiagnosticsView({diagnostic, diagnostics, setCurrentDiagnostic}: DiagnosticsProps) {
  
  return (<>
    <Card sx={{ width: '100%', overflowY: 'scroll', maxHeight: '90vh'}}>
      <div>
        <Typography level="title-lg">
          <span style={{color: diagnostic.mode === RoundMode.Finalized ? 'rgba(var(--joy-palette-success-mainChannel) / 1)' : 'rgba(var(--joy-palette-warning-mainChannel) / 1)'}}>{diagnostic.mode}</span>&nbsp;
          Round</Typography>
        <Typography level="title-md">
          Continues from event: {diagnostic.round.previous === null ? "None" : (<a
           style={{color: 'rgba(var(--joy-palette-primary-mainChannel) / 1)'}}
           href="/#" onClick={(e) => {
            e.preventDefault()
            setCurrentDiagnostic(diagnostics.filter(d => d.round.dialogEvents[d.round.dialogEvents.length-1].id === diagnostic.round.previous)[0])
          }}>{diagnostic.round.previous}</a>)}
        </Typography>
        <IconButton
          variant="plain"
          color="neutral"
          size="sm"
          sx={{ position: 'absolute', top: '0.875rem', right: '0.5rem' }}
        >
          <CloseIcon onClick={() => setCurrentDiagnostic(undefined)} />
        </IconButton>
      </div>
      {diagnostic.round.dialogEvents.map(de => (
      <CardContent orientation="horizontal">
        <Card sx={{ width: '100%' }}>
          <div>
            <Typography level="title-lg">{de.type === DialogEventType.NarrativeEventReference ? ((de as NarrativeEventReference).narrativeEventType) : de.type}</Typography>
            <Typography level="title-sm">ID: {de.id}</Typography>
          </div>
          <CardContent orientation="horizontal">
            <div>
              <Event dialogEvent={de} />
            </div>
          </CardContent>
        </Card>
      </CardContent>
      ))}
    </Card>
  </>);
}