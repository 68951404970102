import * as React from 'react';
import IconButton from '@mui/joy/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import { Modal, ModalDialog, ModalClose, FormControl, FormLabel, Input, Tabs, Tab, TabList, TabPanel } from '@mui/joy';

type SettingsBoxProps = {
    fablecastEndpoint: string;
    setFablecastEndpoint: (value: string) => void;
    puppetKey: string;
    setPuppetKey: (value: string) => void;
    puppetEndpoint: string;
    setPuppetEndpoint: (value: string) => void;
  };

export default function SettingsBox({
    fablecastEndpoint, setFablecastEndpoint,
    puppetEndpoint, setPuppetEndpoint,
    puppetKey, setPuppetKey} : SettingsBoxProps) {
  const [open, setOpen] = React.useState(false);
  return (<>
    <IconButton variant="plain" color="primary"
                size='lg'
                sx={{
                    position: 'absolute',
                    bottom: 8,
                    right: 8,
                    zIndex: 999
                }}
                onClick={() => setOpen(true)}>
        <SettingsIcon />
    </IconButton>
    <Modal open={open}>
        <ModalDialog>
            <ModalClose onClick={() => setOpen(false)}/>
            <Tabs>
              <TabList>
                <Tab>Fablecast</Tab>
                <Tab>Puppet</Tab>
              </TabList>
              <TabPanel value={0}>
                <FormControl>
                    <FormLabel>Endpoint</FormLabel>
                    <Input placeholder="Endpoint" value={fablecastEndpoint} onChange={e => setFablecastEndpoint(e.target.value)}/>
                </FormControl>
              </TabPanel>
              <TabPanel value={1}>
                <FormControl>
                    <FormLabel>Endpoint</FormLabel>
                    <Input placeholder="Endpoint" value={puppetEndpoint} onChange={e => setPuppetEndpoint(e.target.value)}/>
                    <FormLabel>API Key</FormLabel>
                    <Input placeholder="Endpoint" value={puppetKey} onChange={e => setPuppetKey(e.target.value)}/>
                </FormControl>
              </TabPanel>
            </Tabs>

        </ModalDialog>
    </Modal>
    </>
  );
}