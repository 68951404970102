import * as React from 'react';
import { CssVarsProvider, extendTheme } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import SettingsBox from './components/SettingsBox';
import themeColors from './Colors';
import Sidebar from './components/Sidebar';
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import { Authenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import { signInWithRedirect, fetchAuthSession } from 'aws-amplify/auth';
import { CircularProgress, Typography } from '@mui/joy';
import testEnvVars from './env_vars.test.json';
import prodEnvVars from './env_vars.prod.json';
import PuppetHome from './components/PuppetHome';
import PuppetMain, { puppetLoader } from './components/PuppetMain';
import Fablecast, { FablecastLogin } from './components/Fablecast';
import FablecastController from './controller/FablecastController';
import Narratives from './components/Narratives';
import LangFuse from './components/LangFuse';

let envVars = testEnvVars;

if (window.location.origin === 'https://prod.toolbox.mindmage.ai' || window.location.origin === 'https://toolbox.mindmage.ai') {
  envVars = prodEnvVars;
}

Amplify.configure({
  Auth: {
    Cognito: {
        userPoolId: envVars.userPoolId,
        userPoolClientId: envVars.userPoolClientId,
        loginWith: {
          oauth: {
            domain: envVars.authDomain,
            scopes: ['email', 'openid'],
            redirectSignIn: [window.location.origin],
            redirectSignOut: [window.location.origin],
            responseType: 'code',
            providers: [{'custom': envVars.idpProvider}]
          },
          email: true
        }
    }
  }
});


const theme = extendTheme({
  "colorSchemes": {
    "light": {
      "palette": themeColors
    },
    "dark": {
      "palette": themeColors
    }
  }
});

function App() {
  const [puppetEndpoint, setPuppetEndpoint] = React.useState<string>(localStorage.getItem('puppetEndpoint') || "https://api.puppet.prod.toolbox.mindmage.ai");
  const [puppetKey, setPuppetKey] = React.useState<string>(localStorage.getItem('puppetKey') || "");
  const [fablecastEndpoint, setFablecastEndpoint] = React.useState<string>(localStorage.getItem('fablecastEndpoint') || "wss://test.api.fablecast.ai/ws");
  const [authed, setAuthed] = React.useState<boolean>(false);
  const [userJwt, setUserJwt] = React.useState<string>();
  const [loginError, setLoginError] = React.useState<string>();

  const narrativeEndpoints = {
    narrativeSearchEndpoint: envVars.narrativeSearchEndpoint,
    narrativeCreateEndpoint: envVars.narrativeCreateEndpoint,
    narrativeUpdateEndpoint: envVars.narrativeUpdateEndpoint,
    narrativePublishEndpoint: envVars.narrativePublishEndpoint,
    narrativeDeleteEndpoint: envVars.narrativeDeleteEndpoint,
    narrativeFetchEndpoint: envVars.narrativeFetchEndpoint
  }
  const langfuseUrl = envVars.langfuseUrl;

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
          <Box component="main" className="MainContent" sx={{ display: 'flex', minHeight: '100dvh', flex: 1 }}>
            <Sidebar langfuseUrl={langfuseUrl} />
            <Box
              component="main"
              className="MainContent"
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                minWidth: 0,
                height: '100dvh',
                gap: 1,
                overflow: 'auto',
              }}
            >
              <Outlet />
            </Box>
          </Box>
      ),
      children: (userJwt === undefined ? [] : [
        {
          path: "/",
          element: <Fablecast/>,
        },
        {
          path: "/oauth2/idpresponse",
          element: <FablecastLogin/>,
        },
        {
          path: "/narratives",
          element: <Narratives narrativeEndpoints={narrativeEndpoints} userJwt={userJwt!}/>,
        },
        {
          path: "/langfuse",
          element: <LangFuse langfuseUrl={langfuseUrl}/>,
        },
        {
          path: "/puppet",
          element: <PuppetHome/>
        },
        {
          path: "/puppet/:jobQueueId",
          element: <PuppetMain puppetEndpoint={puppetEndpoint} puppetKey={puppetKey}/>,
          loader: puppetLoader,
        }
      ])
    },
  ]);

  React.useEffect(() => {
    localStorage.setItem('fablecastEndpoint', fablecastEndpoint);
    localStorage.setItem('puppetEndpoint', puppetEndpoint);
    localStorage.setItem('puppetKey', puppetKey);
  }, [fablecastEndpoint, puppetEndpoint, puppetKey]);

  React.useEffect(() => {
    async function getUserAttributes() {
        let isNowAuthed = false;
        try {
            const session = await fetchAuthSession();
            setUserJwt(session.tokens?.accessToken.toString())
            isNowAuthed = session.userSub !== undefined;
            setAuthed(isNowAuthed);
        } catch (error) {
            isNowAuthed = false;
        }

        if (!isNowAuthed) {
          
          const errorMessage = new URL(window.location.toString()).searchParams.get('error_description');
          if (errorMessage === null) {
            await signInWithRedirect({
              provider: {'custom': 'MicrosoftIdp-test'}
            })
          } else {
            setLoginError(errorMessage);
          }
          setAuthed(false);
        }
    }
    if (!authed) {
      getUserAttributes();
    } 
  }, [authed, setAuthed, setUserJwt]);

  React.useEffect(() => {
    FablecastController.setEndpoint(fablecastEndpoint);
  }, [fablecastEndpoint]);

  return (<CssVarsProvider disableTransitionOnChange defaultMode={"dark"} theme={theme} modeStorageKey={"mmtoolboxmode"} disableNestedContext>
    <CssBaseline />
      {authed ? (
        <Authenticator.Provider>
            <RouterProvider router={router}/>
            <SettingsBox
              fablecastEndpoint={fablecastEndpoint} setFablecastEndpoint={setFablecastEndpoint}
              puppetEndpoint={puppetEndpoint} setPuppetEndpoint={setPuppetEndpoint}
              puppetKey={puppetKey} setPuppetKey={setPuppetKey}
              />
        </Authenticator.Provider>
      ) : (
        <Box sx={{
          width: '100vw',
          height: '100vh',
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: 'center'}}>
            {loginError === undefined ? (<CircularProgress size='lg'/>) : (<Typography level="body-md">{loginError}</Typography>)}
        </Box>

      )}
    </CssVarsProvider>
  );
}

export default App;
