import * as React from 'react';
import BugReportIcon from '@mui/icons-material/BugReport';
import { DiagnosticPayload, RoundMode } from '../controller/FablecastMessage';
import {  IconButton } from '@mui/joy';

export interface InternalMessageBubbleProps {
  diagnostic: DiagnosticPayload
  setCurrentDiagnostic: React.Dispatch<React.SetStateAction<DiagnosticPayload | undefined>>;
}

export default function InternalMessageBubble({diagnostic, setCurrentDiagnostic}: InternalMessageBubbleProps) {
  return  (<IconButton onClick={() => setCurrentDiagnostic(diagnostic)}><BugReportIcon color={diagnostic.mode === RoundMode.Prospective ? "warning" : "success"} /></IconButton>)
}