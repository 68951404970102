import * as React from 'react';
import Box from '@mui/joy/Box';
import Modal from '@mui/joy/Modal';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import Textarea from '@mui/joy/Textarea';
import { IconButton, Input, Stack } from '@mui/joy';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import FablecastController from '../controller/FablecastController';
import InsertComment from '@mui/icons-material/InsertComment';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import CloseIcon from '@mui/icons-material/Close';
import { ErrorPayload } from '../controller/FablecastMessage';

export type MessageInputProps = {
  identityToken: string;
  setIdentityToken: (value: string) => void;
  textAreaValue: string;
  setTextAreaValue: (value: string) => void;
  onSubmit: () => void;
  error: ErrorPayload | undefined;
  setError: (error: ErrorPayload | undefined) => void
  annotationAreaValue: string;
  setAnnotationAreaValue: (value: string) => void;
  onModalClose: () => Promise<void>;
  onStartTalking: () => void
};

export default function MessageInput(props: MessageInputProps) {
  const {
    textAreaValue,
    setTextAreaValue,
    onSubmit,
    identityToken,
    setIdentityToken,
    error,
    setError,
    annotationAreaValue,
    setAnnotationAreaValue,
    onModalClose,
    onStartTalking
  } = props;

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const textAreaDivRef = React.useRef<HTMLDivElement>(null);

  const handleAnnotateClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    onModalClose().then(() => {
      setIsModalOpen(false)}
    );
    
  };
  const handleClick = () => {
    if (textAreaValue.trim() !== '') {
      onSubmit();
      setTextAreaValue('');
    }
  };

  const disconnect = () => {
    FablecastController.disconnect(() => setConnected(FablecastController.connected()));
  };

  React.useEffect(() => {
    setConnected(FablecastController.connected())
  }, [error])
  
  const handleLogin = async () => {
    const cognitoUri = 'fablecast.auth.eu-west-2.amazoncognito.com'
    const cognitoClientId = '7onsg7ggn50jsni31bosk2f2vv';
    const redirectUri = `${window.location.origin}/oauth2/idpresponse`;
    const idp = 'MindMageActiveDirectory'


    const cognitoLoginUrl = `https://${cognitoUri}/login?client_id=${cognitoClientId}&response_type=token&identity_provider=${idp}&scope=openid+email&redirect_uri=${encodeURIComponent(redirectUri)}`;
    const loginWindow = window.open(cognitoLoginUrl, '_blank', 'width=600,height=600');

    if (loginWindow) {
      const checkWindowClosed = setInterval(() => {
        if (loginWindow.closed) {
          clearInterval(checkWindowClosed);
          setIdentityToken(localStorage.getItem('fablecastIdentityToken') || '');
        }
      }, 500);
    }
  };

  const connect = () => {
    try {
      setAnnotationAreaValue('')
      FablecastController.connect(identityToken, () => setConnected(FablecastController.connected()));
    } catch (error: any) {
      setError(new ErrorPayload(Date.now(), (error as Error).message, "CANT_CONNECT", undefined));
    }
  };

  const [connected, setConnected] = React.useState<boolean>(FablecastController.connected());

  return (
    <Box sx={{ px: 2, pb: 3 }}>
      <Textarea
        placeholder="Input…"
        ref={textAreaDivRef}
        onChange={(e) => { setTextAreaValue(e.target.value); onStartTalking();}}
        value={textAreaValue}
        minRows={3}
        maxRows={10}
        endDecorator={
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="right"
            flexGrow={1}
            sx={{
              pt: 0.5,
              pr: 1,
              borderTop: '1px solid',
              borderColor: 'divider',
              overflowX: 'auto'
            }}
          >
            { connected ? (
              <>
               <Button
                  size="sm"
                  color="primary"
                  sx={{ alignSelf: 'center', borderRadius: 'sm' }}
                  endDecorator={<CloseIcon />}
                  onClick={disconnect}
                >
                  Disconnect
                </Button>
                <span style={{flex: 2}}/>
                <Button
                  size="sm"
                  color="primary"
                  sx={{ alignSelf: 'center', borderRadius: 'sm' }}
                  endDecorator={<InsertComment />}
                  onClick={handleAnnotateClick}
                >
                  Annotate
                </Button>
                <Button
                  size="sm"
                  color="primary"
                  sx={{ alignSelf: 'center', borderRadius: 'sm' }}
                  endDecorator={<SendRoundedIcon />}
                  onClick={handleClick}
                >
                  Send
                </Button>
              </>
            )
              : (
                <>
                  <Box sx={{ pl: 1, pr: 1, display: 'flex', width: '100%' }}>
                    {setIdentityToken !== undefined && (
                      <Input
                        type="text"
                        id="sesssionIdBox"
                        startDecorator={<FingerprintIcon />}
                        endDecorator={<IconButton onClick={handleLogin}><VpnKeyIcon /></IconButton>}
                        value={identityToken}
                        onChange={(e) => setIdentityToken(e.target.value)}
                        sx={{ fontSize: 'sm', textAlign: 'right', flex: 2 }} />
                    )}

                  </Box>
                  <Button
                    size="sm"
                    color="primary"
                    sx={{ alignSelf: 'center', borderRadius: 'sm' }}
                    endDecorator={<PlayCircleFilledIcon />}
                    onClick={connect}
                  >
                    Connect
                  </Button>
                </>
              )}
          </Stack>
        }
        onKeyDown={(event: React.KeyboardEvent<HTMLTextAreaElement>) => {
          if (event.key === 'Enter' && !(event.metaKey || event.ctrlKey || event.altKey)) {
            event.preventDefault();
            handleClick();
          } else if (event.key === 'Enter') {
            const cursorPosition = (event.target as HTMLTextAreaElement).selectionStart;
            let textBeforeCursorPosition = textAreaValue.substring(0, cursorPosition)
            let textAfterCursorPosition = textAreaValue.substring(cursorPosition, textAreaValue.length)
            setTextAreaValue(`${textBeforeCursorPosition}\n${textAfterCursorPosition}`)
          }
        }}
        sx={{ '& textarea:first-of-type': { minHeight: 72 } }}
      />
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box sx={{ 
          p: 2, 
          bgcolor: 'background.paper', 
          borderRadius: 1, 
          width: '500px', 
          height: '500px',
          margin: 'auto', 
          position: 'absolute', 
          top: '50%', 
          left: '50%', 
          transform: 'translate(-50%, -50%)' 
        }}>
          <Typography level="h4" component="h2">
            Annotate
          </Typography>
          <Textarea
            placeholder="Enter your annotation here..."
            minRows={5}
            maxRows={10}
            sx={{ mt: 2, mb: 2 }}
            onChange={(e) => { setAnnotationAreaValue(e.target.value); }}
            value={annotationAreaValue}
          />
          <Button onClick={handleCloseModal} variant="outlined">
            Close
          </Button>
        </Box>
      </Modal>
    </Box>
  );
}
